/* Default styles for the mobile menu */
.mobile-menu-container {
    position: relative;
  }
  
  .menu-toggle-button {
    font-size: 18px;
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 10px;
  }
  
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 9999;
    padding: 20px;
    overflow-y: auto;
  }
  
  .mobile-menu.open {
    transform: translateX(0);
  }
  
  .mobile-menu-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .categories-list {
    margin-top: 20px;
  }
  
  .category-item {
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
  }
  
  .category-header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .category-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .default-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .category-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .subcategory-list {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .subcategory-item {
    padding: 8px 0;
    cursor: pointer;
    font-size: 14px;
    color: #555;
  }
  
  .subcategory-item:hover {
    background-color: #f1f1f1;
  }
  
  @media (max-width: 768px) {
    /* Adjustments for smaller screens */
    .menu-toggle-button {
      font-size: 16px;
      padding: 8px;
    }
  
    .category-name {
      font-size: 14px;
    }
  }
  