/* Login Form CSS */
.login-form-container {
    background-color: #f8f9fa; /* Background of the form */
    padding: 3rem;
    width: 40%;
    margin: 3rem auto;

}

.loginpage_heading {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.input-group {
    margin-bottom: 1rem;
}

.login-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.signup-text {
    margin: 1rem 0;
    font: italic;
}

.signin_link {
    color: #006AA7;
}

.signin_link:hover {
    color: #066fac;
    text-decoration: underline;
}

/* CSS */
.checkbox-input {
    accent-color: #0e675e; /* Change the background color */
}

.fix-width-input{
    width: 100% !important;
}

.MuiFormControl-root {
    width: 100% !important;
  }

  .btn-login 
  {
    margin-top: 2rem;
  }




@media (max-width: 850px) {
    .login-form-container {
        width: 100%;
        margin: 0;
        background-color: #fff;
    }

   
}

@media (min-width: 850px) and (max-width: 1100px) {
    .login-form-container {
        width: 60%;
    }
}
 