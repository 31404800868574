.order-success {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f9f9f9; /* Matching background */
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
}

.order-success-container {
    background: white;
    border-radius: 8px; /* Matching border radius */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    max-width: 600px;
    width: 100%;
    padding: 20px; /* Reduced padding for consistency */
    border: 1px solid #ddd; /* Matching border */
    text-align: center;
    margin: 10px;
}

.order-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333; /* Matching text color */
    margin-bottom: 10px;
}

.order-message {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.order-summary,
.shipping-address {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd; /* Consistent with other containers */
}

.expected-delivery {
    font-size: 1rem;
    color: #4caf50;
    margin-bottom: 20px;
    font-weight: bold;
}

.btn-animated {
    display: inline-block;
    padding: 12px 20px;
    background: #ff6b6b;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(255, 107, 107, 0.3);
    transition: all 0.3s ease;
}

.btn-animated:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(255, 107, 107, 0.5);
}

/* Scrollable section styles similar to cart */
.order-summary,
.shipping-address {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f9f9f9;
}

.order-summary::-webkit-scrollbar,
.shipping-address::-webkit-scrollbar {
    width: 8px;
}

.order-summary::-webkit-scrollbar-track,
.shipping-address::-webkit-scrollbar-track {
    background: #f9f9f9;
}

.order-summary::-webkit-scrollbar-thumb,
.shipping-address::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 2px solid #f9f9f9;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideInLeft {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInRight {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInDown {
    from { transform: translateY(-100%); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
    .order-success-container {
        padding: 20px;
        max-width: 90%;
    }

    .order-heading {
        font-size: 1.4rem;
    }

    .order-message {
        font-size: 0.95rem;
    }

    .expected-delivery {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .order-success-container {
        padding: 15px;
        max-width: 100%;
    }

    .order-heading {
        font-size: 1.3rem;
    }

    .order-message {
        font-size: 0.9rem;
    }

    .btn-animated {
        padding: 10px 15px;
        font-size: 0.9rem;
    }
}
