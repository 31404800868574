.wrapper {
  position: fixed;
  z-index: 20;
  bottom: 30px;
  right: 20px;
  max-width: 500px;
  width: 100%;
  background: #fff;
  border-radius: 12px;

  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
}

.wrapper .MuiTypography-root {
  font-size: 14px;
  color: #333;
  text-align: center;
}

.wrapper .MuiButton-root {
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
}

.wrapper .MuiButton-containedPrimary {
  background-color: #1b84c0;
  color: #fff;
}

.wrapper .MuiButton-containedPrimary:hover {
  background-color: #6eaacc;
}

.wrapper .MuiButton-outlinedSecondary {
  color: #1b84c0;
  border-color: #1b84c0;
}

.wrapper .MuiButton-outlinedSecondary:hover {
  background-color: #6eaacc;
  border-color: #6eaacc;
  color: white;
}

.wrapper .MuiDivider-root {
  margin: 8px 0;
}

.wrapper .cookies-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.wrapper .MuiButton-containedSuccess {
  background-color: #39b54a;
  color: #fff;
}

.wrapper .MuiButton-containedSuccess:hover {
  background-color: #2f9c3d;
}
