.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.loader img {
    width: 23%;
    animation-name: grow;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes grow {
    0% {
        width: 23%;
    }

    50% {
        width: 25%;
    }

    100% {
        width: 23%;
    }
}

@media screen and (max-width: 899px) {
    .loader img {
        width: 58%;
        animation-name: grow;
        animation-duration: 2s;
        animation-timing-function: ease-out;
        animation-delay: 0;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
    }

    @keyframes grow {
        0% {
            width: 58%;
        }

        50% {
            width: 60%;
        }

        100% {
            width: 58%;
        }
    }

}