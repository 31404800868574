.product-component-card-2 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  border: none;
  /* Removed default border */
}

.product-component-card-2:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.product-image-section-2 {
  height: 250px;
  /* Image occupies 40% of the card's height */
  position: relative;
  /* Center vertically */
}

.product-component-image-2 {
  width: 100%;
  height: 100%;
  object-fit: contain !important; /* Ensures the image is contained within the box without overflow */
  transition: transform 0.3s ease;
}

.product-component-card-2:hover .product-component-image-2 {
  transform: scale(1.05);
}

.product-details-section-2 {
  flex: 1;
  /* Details occupy the remaining 60% */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background-color: #f9f9f9;
}

.product-component-info-2 {
  text-align: center;
}

.product-component-title-2 {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  margin-bottom: 0px;
  color: #333333;
  height: 28px;
  /* Fixed height to ensure consistency */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-component-rating-2 {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.product-component-pricing-2 {
  text-align: center;
  margin-bottom: 12px;
}

.product-component-price-2 {
  font-size: 1rem;
  font-family: 'Jost', sans-serif;
  color: #045149;
  margin-bottom: 4px;
}

.star-2 {
  color: #ccc;
  margin: 0 2px;
  font-size: 1rem;
}

.star.filled-2 {
  color: #f39c12;
}

.original-price-2 {
  text-decoration: line-through;
  color: #999999;
  font-size: 0.7rem;
  margin-right: 8px;
}

.discounted-price-2 {
  color: #e63946;
  font-weight: bold;
}

.btn-wrap-2 {
  text-align: center;
}

.btn-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #006AA7;
  color: #ffffff;
  padding: 7px 15px;
  border-radius: 24px;
  font-family: 'Jost', sans-serif;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-2:hover {
  background-color: #004f7a;
  transform: translateY(-2px);
}

.cart-icon-2 {
  margin-right: 8px;
  font-size: 0.95rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .product-component-card-2 {
    flex-direction: column;
    max-height: 450px;
  }

  .product-image-section-2 {
    height: 180px;
  }

  .product-component-title-2 {
    font-size: 1rem;
    height: 36px;
  }

  .product-component-price-2 {
    font-size: 0.95rem;
  }

  .btn-2 {
    padding: 8px 16px;
    font-size: 0.85rem;
  }

  .star-2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .product-component-card-2 {
    flex-direction: column;
    max-height: 500px;
  }

  .product-image-section-2 {
    height: 220px;
  }

  .product-component-title-2 {
    font-size: 1rem;
    height: 48px;
  }

  .product-component-price-2 {
    font-size: 0.9rem;
  }

  .btn-2 {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .star-2 {
    font-size: 1rem;
  }

  .product-component-image-2 {
    object-fit: cover;
  }
}

@media (max-width: 480px) {
  .product-component-card-2 {
    flex-direction: column;
    max-height: 550px;
  }

  .product-image-section-2 {
    height: 200px;
  }

  .product-component-title-2 {
    font-size: 0.9rem;
    height: 40px;
  }

  .product-component-price-2 {
    font-size: 0.85rem;
  }

  .btn-2 {
    padding: 6px 12px;
    font-size: 0.85rem;
  }

  .star-2 {
    font-size: 0.9rem;
  }
}
