.cart-product {
  display: grid;
  grid-template-columns: 100px 1fr auto; /* Define grid structure */
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: box-shadow 0.3s;
}

.cart-product:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

.cart-product-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  display: block;             /* Ensures image can be centered */
  margin: 0 auto;             /* Centers image horizontally */
}

.cart-product-details {
  padding: 0 15px; /* Add horizontal padding */
}

.cart-product-name {
  font-size: 16px;
  color: #333;
    /* Adds ellipsis for overflow */
}



.cart-product-price {
  font-size: 14px; /* Font size for price */
  color: #777; /* Lighter color for price */
}

.cart-product-variations {
  margin: 8px 0;
  font-size: 14px;
  color: #555; /* Subtle variation color */
}

.cart-product-variation {
  margin: 4px 0;
}

.cart-product-quantity {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity-label {
  margin-right: 10px; /* Space between label and input */
}

.quantity-input-container {
  display: flex;
  align-items: center;
}

.quantity-input {
  width: 40px; /* Adjust input width */
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
}

.quantity-btn {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  font-size: 20px; /* Larger font for buttons */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-btn:hover {
  background-color: #e0e0e0;
}

.cart-product-delete {
  margin-top: 10px; /* Space above delete button */
  padding: 6px 12px;
  font-size: 14px;
  background-color: #d9534f; /* Bootstrap danger color */
  color: white; /* White text */
  border: none; /* Remove border */
  border-radius: 3px; /* Rounded corners */
  cursor: pointer;
}

.cart-product-delete:hover {
  background-color: #c9302c; /* Darker red on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .cart-product {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }

  .cart-product-image {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 10px; /* Space below image */
  }

  .cart-product-delete {
    margin-top: 10px; /* Space above delete button */
  }
}

@media (max-width: 480px) {
  .cart-product {
    grid-template-columns: 1fr; /* Single column layout */
    padding: 10px; /* Reduced padding */
  }

  .cart-product-image {
    width: 50%; /* Smaller width for image */
    margin-bottom: 8px; /* Reduced space below image */
  }

  .cart-product-details {
    padding: 0 10px; /* Smaller padding */
  }

  .cart-product-name {
    font-size: 10px;
    color: #333;
   /* Adds ellipsis for overflow */
  }

  .cart-product-price {
    font-size: 12px; /* Reduced font size */
  }

  .cart-product-variations,
  .cart-product-variation {
    font-size: 12px; /* Smaller font size for variations */
  }

  .cart-product-quantity {
    margin-top: 8px; /* Reduced top margin */
  }

  .quantity-label {
    font-size: 12px; /* Smaller font size for label */
    margin-right: 8px; /* Reduced space between label and input */
  }

  .quantity-input-container {
    gap: 3px; /* Reduced space between buttons and input */
  }

  .quantity-input {
    width: 30px; /* Smaller input width */
    padding: 4px; /* Reduced padding */
  }

  .quantity-btn {
    width: 20px; /* Smaller button */
    height: 20px; /* Smaller button */
    font-size: 16px; /* Smaller font size */
  }

  .cart-product-delete {
    padding: 4px 6px; /* Reduced padding */
    font-size: 12px; /* Smaller font size */
    margin-top: 8px; /* Smaller margin above */
  }
}