.newsletter-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    padding: 15px; /* To ensure spacing on smaller screens */
    box-sizing: border-box;
}

.newsletter-modal {
    background: #fff;
    width: 80%;
    max-width: 900px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: row;
}

.newsletter-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    justify-content: space-between;
}

.newsletter-image {
    flex: 1;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}

.newsletter-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.newsletter-text {
    flex: 1.5;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.newsletter-text h2,
.newsletter-text h3 {
    margin: 0 0 15px 0;
    font-size: 24px;
}

.newsletter-text h2 .highlight,
.newsletter-text h3 .highlight {
    color: #e63946;
}

.newsletter-text p {
    margin: 15px 0;
    font-size: 16px;
    color: #555;
}

.newsletter-input {
    width: 70%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.newsletter-submit {
    width: 70%;
    padding: 10px;
    background-color: #3778ce;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.newsletter-submit:hover {
    background-color: #6b9ddd;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.newsletter-social {
    margin: 15px 0;
    font-size: 24px;
}

.newsletter-social i {
    margin-right: 10px;
    color: #555;
    cursor: pointer;
}

.newsletter-social i:hover {
    color: #e63946;
}

.newsletter-no-thanks {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.newsletter-no-thanks label {
    margin-left: 5px;
    font-size: 14px;
    color: #555;
}

/* Responsive Design */

@media (max-width: 768px) {
    .newsletter-modal {
        flex-direction: column;
        width: 90%;
        max-width: 600px;
    }

    .newsletter-content {
        flex-direction: column;
        padding: 20px;
    }

    .newsletter-image {
        height: 200px;
        border-radius: 10px 10px 0 0;
    }

    .newsletter-text {
        padding-left: 0;
        padding-top: 20px;
    }

    .newsletter-input,
    .newsletter-submit {
        width: 100%;
        font-size: 16px;
    }

    .newsletter-text h2,
    .newsletter-text h3 {
        font-size: 20px;
        text-align: center;
    }

    .newsletter-text p {
        text-align: center;
        font-size: 14px;
    }

    .newsletter-social {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .newsletter-modal {
        width: 100%;
        padding: 10px;
    }

    .newsletter-text h2,
    .newsletter-text h3 {
        font-size: 18px;
    }

    .newsletter-input,
    .newsletter-submit {
        font-size: 14px;
    }

    .newsletter-social {
        font-size: 20px;
    }
}
