/* Slider container styling */
.slider-container-2 {
    position: relative;         /* Ensures positioning context */
    width: 100%;               /* Full width of the parent container */
    overflow: hidden;          /* Hide overflow content to prevent overlap */
    padding: 20px 0;          /* Add padding around the slider */
   
}

/* Slick slide styles */
.slick-slide-2 {
    display: flex;             /* Align items in the center */
    justify-content: center;   /* Center content horizontally */
    align-items: flex-start;   /* Align items to the start */
    padding: 10px;            /* Add spacing between slides */
}

/* Product card styling */
.product-card-2 {
    background-color: #fff;    /* White background for cards */
    border-radius: 8px;        /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for cards */
    overflow: hidden;          /* Prevent content overflow */
    transition: transform 0.3s ease; /* Smooth transform effect on hover */
    width: 100%;               /* Take full width of the slide */
    max-width: 300px;          /* Set a max width for cards */
}

/* Hover effect for product cards */
.product-card-2:hover {
    transform: scale(1.05);    /* Scale effect on hover */
}

/* Responsive adjustments */
@media (max-width: 1100px) {
    .slick-slide-2 {
        padding: 5px;         /* Reduced padding for smaller screens */
    }
}

@media (max-width: 600px) {
    .product-card-2 {
        max-width: 80%;       /* Allow more space for smaller screens */
    }
}

@media (max-width: 480px) {
    .product-card-2 {
        max-width: 100%;      /* Full width on very small screens */
    }
}
