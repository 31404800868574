@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  /*font-family: "Open Sans", sans-serif !important;*/
  font-family: "Jost", "Open Sans", sans-serif;
  background-color: #f2f2f2;
  font-size: 1rem;
}


table {
  font-family: "Jost", "Open Sans", sans-serif;
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*.btn-primary {*/
/*    background: #006AA7;*/
/*    border: none !important;*/
/*    border-radius: 30px !important;*/
/*    width: 141px;*/
/*    font-size: 1rem;*/
/*    padding: 12px 0;*/
/*    color: #fff;*/
/*}*/

/*.btn-primary:hover, .btn-primary:active, .btn-primary:focus {*/
/*    background: #0471b1 !important;*/
/*    border: none !important;*/
/*    box-shadow: none !important;*/
/*    outline: none !important;*/
/*}*/

/*.btn-dark {*/
/*    background: #1F2937;*/
/*    border: none !important;*/
/*    border-radius: 0 !important;*/
/*    width: 141px;*/
/*    padding: 10px 0;*/
/*    color: #fff;*/
/*}*/

/*.btn-dark:hover, .btn-dark:active, .btn-dark:focus {*/
/*    background: #1d2633 !important;*/
/*    border: none !important;*/
/*    box-shadow: none !important;*/
/*    outline: none !important;*/
/*}*/

/*.btn-light {*/
/*    background: #e4e4e4;*/
/*    border: none !important;*/
/*    border-radius: 30px !important;*/
/*    width: 141px;*/
/*    padding: 10px 0;*/
/*    color: #000;*/
/*}*/

/*.btn-light:hover, .btn-light:active, .btn-light:focus {*/
/*    background: #dbd4d4 !important;*/
/*    border: none !important;*/
/*    box-shadow: none !important;*/
/*    outline: none !important;*/
/*}*/

/*.btn-success {*/
/*    background: #006AA7;*/
/*    border: none !important;*/
/*    border-radius: 30px !important;*/
/*    width: 141px;*/
/*    padding: 10px 0;*/
/*    color: #fff;*/
/*}*/

/*.btn-success:hover, .btn-success:active, .btn-success:focus {*/
/*    background: #006AA7 !important;*/
/*    border: none !important;*/
/*    box-shadow: none !important;*/
/*    outline: none !important;*/
/*}*/

/*.btn-outline-primary, .btn-outline-primary-border {*/
/*    border: none !important;*/
/*    border-radius: 30px !important;*/
/*    width: 141px;*/
/*    font-size: 1rem;*/
/*    padding: 9px 0;*/
/*    color: #006AA7;*/
/*    border: 1.89px solid #006AA7 !important;*/
/*}*/

/*.btn-outline-primary:hover {*/
/*    color: #ffff !important;*/
/*    background: linear-gradient(31deg, #006AA7 0%, #006AA7 100%) 0% 0% no-repeat*/

/*}*/

/*.btn-outline-primary:focus {*/
/*    border: 1.89px solid #006AA7 !important;*/
/*}*/

/*.btn-small {*/
/*    width: 141px !important;*/
/*}*/

/*.btn {*/
/*    box-shadow: none !important;*/
/*    outline: none !important;*/
/*}*/

.btn,
.btn:focus {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-size: 0.875rem;
  line-height: 1.75;
  color: white;
  background-color: #006aa7;
  border: none;
  box-shadow: none !important;
  min-width: 0px;
  min-height: 0px;
  font-weight: 600;
  text-transform: capitalize;
  outline: 0;
  border-image: initial;
  margin: 0;
  text-decoration: none;
  padding: 5px 1.75rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 6px;
}

.btn:hover {
  box-shadow: none !important;
  color: white;
  background-color: #448bc4;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-card-border {
  border-left: 1px solid #e1e4ee;
  border-right: 1px solid #e1e4ee;
}

.border-radius-small {
  border-radius: 10px !important;
}

.border-radius-large {
  border-radius: 30px !important;
}

.no-scroll {
  overflow: hidden;
}

/* WebKit Browsers (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 15px; /* Height of the scrollbar */
}

body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

body::-webkit-scrollbar-thumb {
  background: #dad8d8; /* Thumb color */
  border-radius: 6px; /* Rounded corners */
}

body::-webkit-scrollbar-thumb:hover {
  background: #898989; /* Thumb color on hover */
}

.search-field {
  background: none;
  padding: 0 0 0 30px;
  width: 100%;
  border: none;
  outline: none;
}

.custom-search-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px 5px;
  background: white;
  width: 550px;
  max-width: 750px;
  margin: auto;
}

.custom-search-box svg {
  position: absolute;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: normal;
}

h1 {
  font-size: 2rem;
}

h1.product-detail-heading {
  text-transform: none;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

.slick-active > div {
  padding-left: 15px;
  padding-right: 1px;
}

.slick-current > div {
  padding-left: 0;
}

.todayOffer {
  font-weight: normal;
  color: #fec53e;
  background: #313131;
  padding: 0 5px;
}

.btn-wrap {
  text-align: center;
}

.btn-login {
  float: right;
}

.productSpecRating {
  border-top: 1px solid rgb(218, 225, 231);
}

.productSpecRating ul.tab {
  list-style: none;
  width: 100%;
  float: left;
  padding: 0;
  margin-bottom: 30px;
}

.productSpecRating ul.tab li {
  list-style: none;
  float: left;
  cursor: pointer;
  padding: 10px 15px;
}

.productSpecRating ul.tab li.current {
  color: #22664d;
  border-bottom: 1px solid #22664d;
}
